.page2{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    overflow: hidden;
    width: 98vw;
    max-width: 90rem;
}
.carousel {

    overflow: hidden;
    width: 100%;
    height: 100%;

  }
  
  .carousel-inner {
    width: 500%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
    transition: transform 0.5s ease-in-out;
  }
  
.carousel-inner > * {
    display: inline-block;
}

.carousel-dots{
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
}
.heroDots{
    display: flex;
    align-items: center;
    gap: 23px;
    list-style: none;
    cursor: pointer;
}
.dot{
    width: 15px;
    height: 15px;
    color: white;
    background: white ;
    border-radius: 7.5px;
}
.colored-dot{
    width: 15px;
    height: 15px;
    color: white;
    background: #ebe27f ;
    border-radius: 7.5px;
}
 

.page2-text-container{
    display: flex;
    height: 400px;
    flex-direction: column;
    text-align: left;
    border: 5px solid black;
    border-radius: 30px;
    margin: 20px 150px;
    background-color: rgba(255,255,255,0.6);
}
.page2-description{
    color: black;
    font-size: 1.2em;
    padding: 40px 40px;
    font-weight: 600;
}

.page2-description b{
    color: black;
    font-size: 1em;   
}

@media screen and (max-width:1050px) {
    .page2-title{
        width: auto;
    }
    .page2-title p{
        font-size: 1.25em !important;
        white-space: nowrap;
    }
    .page2-description{
        font-size: 1em;
    }
}


@media screen and (max-width:825px) {
    .page2-title{
        width: 60% !important;
    }
    .page2-title p{
        font-size: 1.1em !important;
        white-space: nowrap;
    }
    .page2-description{
        font-size: 0.9em;
    }
    .page2-text-container{
        margin: 20px 80px;
    }
}


@media screen and (max-width:680px) {
    .page2-title{
        width: 80% !important;
    }
    .page2-title p{
        font-size: 3.5vw !important;
    }
    .page2-description{
        font-size: 2.2vw;
    }
    .page2-text-container{
        margin: 2vw 6vw;
    }
}
@media screen and (max-width:550px) {
    .page2-title{
        width: 100% !important;
        margin: 0 0 !important;
    }
    .page2-title p{
        font-size: 1.1em !important;
        white-space: wrap !important;

    }
    .page2-description{
        margin: 1.5em 1.5em !important;
        padding: 0 0 !important;
        font-size: 0.82em !important;
    }
    .page2-text-container{
        margin: 2vw 1vw;
    }
}