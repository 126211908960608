.founders{
    height: 420px;
    width: 98vw;
    max-width: 90rem;
    margin: 0 auto;
    overflow: hidden;
    position: relative;  
    display: flex;
    justify-content: space-between;
    gap:40px;
    align-items: center;
    background-color: rgb(241, 241, 3);
    border-radius: 50px;
    transition: transform 0.3s ease;
    transform: scale(0.95);
}
.mobile-founders{
    display: none;
}
.card.active{
    transform: scale(1);
}

.aneesh{
    height: 100%;
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: left;
    gap: 20px;
    padding: 0 20px;
    position: relative;
}
.ajinkya{
    height: 100%;
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: right;
    gap: 20px;
    padding: 0 20px;
    position: relative; 
}
.aneesh .founder-img{
    width: 50%;
    height: auto;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: all 0.3s ease;
}
.ajinkya .founder-img:hover{
    transform: scale(1.05);
    cursor: pointer;
}
.aneesh .founder-img:hover{
    transform: scale(1.05);
    cursor: pointer;
}
.ajinkya .founder-img{
    width: 50%;
    height: auto;
    position: absolute;
    bottom: 0;
    right: 0;
    margin-bottom: -0.2em;
    margin-right: -0.1em;
    transition: all 0.3s ease;
}
.aneesh .aneesh-paras{
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    align-items: left;
    position: absolute;
    top: 4em;
    left: 20em;
}
.ajinkya .ajinkya-paras{
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    align-items: right;
    position: absolute;
    text-align: right;
    top: 4em;
    right: 20em;
}
.ajinkya .ajinkya-paras .position{
    margin-bottom: 2em;
}
.description{
    color: black;
    font-size: 1em;
    font-weight: 600;
}
.name{
    font-size: 2em;
    font-weight: 600;
    color: black;
    display: flex;
    flex-direction: column;
    padding-top: 20px ;
}

.position{
    font-size: 1rem;
    font-weight: 500;
    color: black;
}

@media screen and (max-width:1150px){
    .founders{
        height: 30vw;
    }
    .aneesh .aneesh-paras{
        top:4vw;
        left: 25vw;
    }
    .ajinkya .ajinkya-paras{
        top:4vw;
        right: 25vw;
    }
    .name{
        font-size: 3vw;
        padding-top: 2vw;
    }
    .position{
        font-size: 1.4vw;
    }
    .aneesh, .ajinkya{
        padding: 0 2vw;
    }
    .description{
        font-size: 1.5vw;
    }
}

@media screen and (max-width:625px) {
    
    .name{
        font-size: 2.5vw;
    }
}

@media screen and (max-width:450px) {
          
    .founders{
        display: none;
    }
    .mobile-founders {
        width: 98vw;
        max-width: 90rem;
        height: 65vh;
        display: block;
        margin: 1em auto;
        overflow: hidden;
        position: relative;
        background: transparent;
        border-radius: 50px;
      }
      
      .founders-carousel {
        display: flex;
        width: 200%;
        height: 100%;
        transition: transform 0.5s ease;
      }
      
      .mobile-aneesh,
      .mobile-ajinkya {
        flex: 0 0 50%;
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        position: relative;
        background: rgb(241, 241, 3);
      }

    .mobile-aneesh .mobile-founder-img{
        height: 60%;
        width: auto;
        position: absolute;
        bottom: 0;
        left: 0;
        transition: all 0.3s ease;
        cursor: pointer;
    }

    .mobile-ajinkya .mobile-founder-img{
        height: 60%;
        width: auto;
        position: absolute;
        bottom: 0;
        right: 0;
        margin-bottom: -0.2em;
        margin-right: -0.2em;
        transition: all 0.3s ease;
        cursor: pointer;
    }
    .mobile-aneesh .mobile-aneesh-paras{
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;
        align-items: left;
        position: absolute;
        top: 3em;
        left: 11em;
        padding-right: 2em;
    }
    .mobile-ajinkya .mobile-ajinkya-paras{
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;
        align-items: right;
        position: absolute;
        text-align: right;
        top: 3em;
        right: 10em;
        padding-left: 2em;
    }
    .mobile-ajinkya .mobile-ajinkya-paras .mobile-position{
        margin-bottom: 2em;
    }
    .mobile-description{
        color: black;
        font-size: 0.9em;
        font-weight: 600;
    }
    .mobile-name{
        font-size: 1.8em;
        font-weight: 600;
        color: black;
        display: flex;
        flex-direction: column;
        padding-top: 20px ;
    }
    
    .mobile-position{
        font-size: 0.9rem;
        font-weight: 500;
        color: black;
    }
    .mobile-founder-img:hover{
        transform: scale(1);
        padding: 0 0;
    }
}

@media screen and (max-width:375px) {
    .mobile-aneesh-paras, .mobile-ajinkya-paras{
        margin:0;
        padding:0;
    }
    .mobile-description{
        font-size: 4vw;
    }
    .mobile-name{
        font-size: 6vw;
    }
    .mobile-position{
        font-size: 3vw;
    }
}

@media screen and (max-width:330px) {
    .mobile-aneesh .mobile-aneesh-paras{
       padding-right:1em;
       left: 9em;
    }
    .mobile-ajinkya .mobile-ajinkya-paras{
        padding-left:1em;
        right: 9em;
     }
}