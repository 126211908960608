.logoText{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 98vw;
    max-width: 90rem;
}
.logo-wall{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(30,30,30);
    margin: 0 auto;
    padding:3em 2em;
    border-radius: 50px;
}
.logo-wall-child{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 50%;
    transition: all 0.3s ease;
}
.logo-wall-img{
    width: 12em;
    height: 10em;
}
.logo-wall-child:hover{
    transform: scale(1.05);
}
.logo-wall-text{
    font-size: 2.5em;
    font-weight: 600;
    width: 50%;
}
.logo-text{
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    width: 100%;
    background-color: rgb(30,30,30);
    border-radius: 50px;
    margin: 1em auto;
    padding: 1em 1em ;

 }
.logo-text-para{
    color: #cecece;
    font-size: 2em;
    font-weight: 400;
    padding: 1em;
    width: 100%;
}
.logo-text-para>b{
    color:#cecece;
}

@media screen and (max-width:1200px) {
    .logo-text{
        height: 600px;
    }
}
@media screen and (max-width:1000px) {
    .logo-text{
        height: 650px;
    }
}
@media screen and (max-width:900px) {
    .logo-text{
        height: 75vw;
    }
    .logo-text-para{
        font-size: 3.3vw;
    }
    .logo-wall-img{
        width: 18vw;
        height: 15vw;
    }
    .logo-wall-text{
        font-size: 4.5vw;
    }

}
@media screen and (max-width:550px) {
    .logo-wall{
        padding:2em 0.5em;
    }
    .mobile-logo-text{
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgb(20,20,20);
        margin: 1em auto;
        width: 98vw;
        max-width: 90rem;
        border-radius: 50px;
        padding: 2em 2em;
        height: 90vh;
    }
    .mobile-logo-text-para{
        font-size: 1.3em;
        color: #cecece;
    }
    .mobile-logo-text-para b{
        color: #cecece;
        font-size: 1em;
    }

}
@media screen and (max-width:400px) {
    .mobile-logo-text-para{
        font-size: 1.2em;
        color: #cecece;
    }
}

@media screen and (max-width:355px) {
    .mobile-logo-text{
        height: 180vw;
    }
    .mobile-logo-text-para{
        font-size: 5.3vw;
        color: #cecece;
    }
    .mobile-logo-text-para b{
        color: #cecece;
        font-size: 5.3vw;
    }

}