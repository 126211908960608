.services { 
  width: 98vw;
  max-width: 90rem;
  overflow: hidden;
  background-color: black;
  margin-top: 1.3em;
}

.services-container {
  display: flex;
  width: 410%;
}

.services-p {
  font-size: 2em;
  font-weight: 700;
  white-space: nowrap;
  width: 100%;
  color: white;
  animation: marquee 30s linear infinite;
}
.services-p.mobile {
  animation: marquee 40s linear infinite;
}
@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

@media screen and (max-width: 450px) {
  .services {
    width: 100%;
    overflow: hidden;
    background-color: black;
    margin-top: 1.3em;
  }
  
  .services-container {
    display: flex;
    width: 200%; 
  }
  
  .services-p {
    font-size: 2em;
    font-weight: 700;
    white-space: nowrap;
    width: 130%; 
    color: white;
    animation: marquee 10s linear infinite;
  }
  
  @keyframes marquee {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%); 
    }
  }
 
    .services-p {
      font-size: 5vw;
    }

}