.tiles-container :nth-child(2){
    display: none;
}
.tiles-container {
    width: 98vw;
    max-width: 90rem;
    margin: 0 auto; 
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
  }
