.default{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height:73vh;
    width: 98vw;
    max-width: 90rem;
    margin: 0 auto;
    background-color: #e3e3e3;
    position: relative;
    border-radius: 30px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.default-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4vh 2em;
    width: 100%;
}
.logo-header{
    display: flex;
    justify-content: space-between;
    background-color: #f0ff00;
    border-radius: 50px;
    padding: 0.1em 1em;
    position: relative;
    border: 6px solid black;
}
.default-logo{
    width: auto;
    height: 20vh;
    position: absolute;
    top: -50%;
    bottom: 50%;
    transition: all 0.3s ease-in-out;
}
.default-logo:hover{
    transform: scale(1.1);
}
.logo-header-text{
    font-size: 8vh;
    font-weight: 600;
    margin-left: 3em;
    color:black
}
.lets-go{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1em 2em;
    background-color: black;
    border-radius: 50px;
    padding: 0.8em 2em;
    transition: all 0.3s ease;
    cursor: pointer;
}
.lets-go:hover{
    transform: scale(1.1);
}
.lets-go-text{
    font-size: 5vh;
    font-weight: 600;
    color: #f0ff00;
}

.default-description{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2em;
    text-align: center;
    margin-bottom: 19em;
    position: absolute;
    top: 26%;
    z-index: 2;
    width: 90%;
}
.default-description-text{
    color: black;
    font-size: 4vh;
}
.tiles{
    display: flex;
    justify-content: center;
    align-items: center;
}
.tiles canvas{
    border-radius: 50px;
}


.creators-form-container{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e5e5e5;
    width: 100vw;
    max-width: 90rem;
    height: 95vh;
    position: absolute;
    flex-direction: column;
    border-radius: 50px;
    z-index: 999999;
    top:-18%;
}

.close-form{
    position: absolute;
    top: 10%;
    right: 10%;
    display: flex;
    justify-content: space-between;
    gap: 2em;

}
.close-icon{
    width: 3vw;
    cursor: pointer;
    border-radius: 50%;
    padding: 0.5em 0.5em;
    transition: all 0.1s ease;
}
.close-icon:hover{
    background-color: #c5c5c5;
}
.arrow-icon{
    width: 3vw;
    cursor: pointer;
    border-radius: 50%;
    padding: 0.5em 0.5em;
    transition: all 0.1s ease;
}
.arrow-icon:hover{
    background-color: #c5c5c5;
}
.prev-icon{
    transform: rotate(180deg);
}
.creators-form-footer{
    display: flex;
    gap: 50px;
    margin-top: 2em;
    position: absolute;
    bottom: 4%;
    left:10%;
}
.creators-form-footer-text{
    font-size: 1.5em;
    font-weight: 600;
    color: black;
}

.form-carousel-dots{
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
}
.form-dots{
    display: flex;
    align-items: center;
    gap: 23px;
    list-style: none;
}
.form-dot{
    width: 20px;
    height: 20px;
    color: black;
    background: black ;
    border-radius: 50%;
}
.form-colored-dot{
    width: 20px;
    height: 20px;
    color: white;
    background: #1680f9 ;
    border-radius: 50%;
}

.form-slide{
    display: flex;
    width: 98vw;
    max-width: 90rem;
    justify-content: space-between;
    padding: 0 10em;
    margin-top: -10%;
    
}

.details{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.form-header{
    font-size: 2em;
    color: black;
    font-weight: 600;
}

.form-footer{
    font-size: 0.8em;
    color: black;
    font-weight: 600;
}
.creator-name{
    border: none;
    outline: none;
    border-bottom: 4px solid black;
    font-size: 2em;
    color: black;
    font-weight: 600;
    width: 60%;
    margin-top: 1em;
}
.form-button{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2em 0;
    background-color: black;
    border-radius: 50px;
    padding: 0.5em 1.5em;
    transition: all 0.3s ease;
    cursor: pointer;
    height: 3em;
 
}
.form-button-text{
    font-size: 2em;
    font-weight: 600;
    color: white;
}

.dropdown-container {
    position: relative;
    width: 100%;
    margin: 10px 0px;
}

.dropdown-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em 1em;
    background-color: white;
    border: 1px solid #ccc;
    cursor: pointer;
    border-radius: 50px;
    color:black;
    width: 40vw;

}

.dropdown-arrow {
    width: auto;
    height: 15px;
    transition: all 0.3s ease;
}
.dropdown-arrow-rotated{
    transform: rotate(180deg);
    width: auto;
    height: 15px;
    transition: all 0.3s ease;
}
.dropdown-list {
    width: 40vw;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-top: none;
    list-style-type: none;
    padding: 0;
    margin: 0;
    max-height: 200px;
    overflow-y: scroll;
    border-radius: 10px;
}

.dropdown-list li {
    padding: 10px;
    cursor: pointer;
    color: black;
}

.dropdown-list li:hover {
    background-color: #f0f0f0;
}

.form-slide-5, .form-slide-6{
   margin-top: 0;
} 
.form-slide-9 .creator-name, .form-slide-10 .creator-name{
    width: 90%;
}
.creator-everyday-work, .creator-pitch{
    border: 3px solid black;
    border-radius: 1em;
    outline: none;
    background-color: white;
    color : black;
    width: 80%;
    height: 10em;
    resize: none;
    padding: 1em 1em;
    margin-top: 1em;
    font-size: 1.7em;
}

.creator-pitch{
    width: 100%;
}
.country-code{
    border: none;
    outline: none;
    border-bottom: 4px solid black;
    color: black;
    font-size: 2em;
    width: 7%;
    margin-right: 0.1em;
}
.creator-phone{
    width: 24%;
}

.calendar{
    outline: none;
    border: 1px solid black;
    border-radius: 1em;
    padding: 1em 1em;
    color: black;
    margin-top: 0.5em;
    font-size: 1.2em;
}

.genders{
    border: 1px solid black;
    outline: none;
    border-radius: 1em;
    padding: 1em 1em;
    color: black;
    width: 30vw;
    background-color: white;
}

.genders option{
    color: black;
}

.form-footer-2{
    margin-top: 1em;
    font-size: 1.3em;
    font-weight: 600;
    color: black;
}

.form-slide-15{
    flex-direction: column;
    margin-top: 0;
}
.form-slide-15 .form-header{
    font-size: 2.5em;
    margin-bottom: 0.2em;
}
.form-slide-15 .form-footer{
    font-size: 1em;
    font-weight: 600;
    margin-bottom: 0.1em;
}

.slide-15-buttons{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.discord-button, .not-discord-button{
    background-color: #1680f9;
    border: 4px solid black;
    border-radius: 50px;
    padding: 1em 1em;
    margin-top: 3em;
    width: 45vw;
    max-width: 43em;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    cursor: pointer;
}

.discord-button:hover, .not-discord-button:hover{
    transform: scale(1.05);
}

.not-discord-button{
    background-color: #ff3636;
}
.discord-button button{
    border: none;
    outline: none;
}
.discord-button-text{
    font-size: 1.5em;
    font-weight: 600;
    color: white;
}
.count-box{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
}
.count-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 98vw;
    max-width: 90rem;
    margin: 0 auto;
    padding: 1em 1em;
    background-color: black;
    border-radius: 50px;
}
.member-count{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #1680f9;
    border-radius: 50px;
    padding: 0.5em 2em;
}
.count-text{
    font-size: 2em;
    color: black;
    font-weight: 600;
    white-space: nowrap;
}
.count-number{
    font-size: 2em;
    font-weight: 700;
    background-color: black;
    border: 50px;
    padding: 0.4em 1.5em;
    border-radius: 50px;
    margin-left: 1em;
}
.socials{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
}
.icon{
    object-fit: cover;
    width: 4rem;
}

.icon:hover{
    transform: scale(1.1);
    transition: all 0.3s ease-in-out;
}

.creator-submit{
    background: black;;
    font-weight: 600;
    font-size: 2em;
    border: none;
    padding: 0.4rem 2.4rem;
    border-radius: 50px;
    margin: 2rem;
    cursor: pointer;
    transition: all 0.2s ease;
    width: 10em;
}
.creator-submit:hover{
    background: white;
    color: black;
    transform: scale(1.05);
}


.error-message{
    color: rgba(255,0,0,0.6);
}

.form-slide-14{
    flex-direction: column;
    margin-top: 0;
}
.form-slide-14 .form-header{
    font-size: 2.5em;
    margin-bottom: 0.1em;
}
.form-slide-14 .form-button{
    width: 20%;
}
.form-button:hover{
    transform: scale(1.05);
}

.form-slide-3 .dropdown-container{
    width: 70%;
}
.form-slide-7 .dropdown-container{
    width: 70%;
}

@media screen and (max-width:1160px) {
    .form-slide-11 .country-code{
        width: 20%;
    }
    .discord-button-text{
        font-size: 2vw;
    } 

}
@media screen and (max-width:1115px) {
    .creators-form-footer{
        left:5%;
    }   

}
@media screen {
    
}
@media screen and (max-width:1050px) {
       

        .form-dots{
            display: flex;
            align-items: center;
            gap: 1.6vw;
            list-style: none;
        }
        .form-dot{
            width: 2vw;
            height: 2vw;
            color: black;
            background: black ;
            border-radius: 50%;
        }
        .form-colored-dot{
            width: 2vw;
            height: 2vw;
            color: white;
            background: #1680f9 ;
            border-radius: 50%;
        }
        .discord-button-text{
            font-size: 1.5vw;
        } 
          
}

@media screen and (max-width:1020px) {
    .logo-header-text{
        font-size: 6vw;
    }
    .default-logo{
        top: -60%;
        left:2%;
        height: 17vw;
    }
    .lets-go-text{
        font-size: 4.5vw;
    }
}


@media screen and (max-width:925px) {
    .default-header{
        padding: 4vh 1em;
        /* border: 2px solid red; */
    }
    .lets-go{
        margin: 0 0;
    }
    .form-slide{
        padding: 0 2em;
    }    
    
}

@media screen and (max-width:840px) {

    .count-text{
        font-size: 4.5vw;
    }
    .count-number{
        font-size: 3vw;
    }

    .icon{
        width: 8vw;
    }
    
    .default-header{
        padding: 5vh 1em;
    }
    
     
    .default-logo{
        height: 18vw;
    }
    .logo-header-text{
        font-size: 7vw;
        white-space: nowrap;
    }
    .lets-go-text{
        font-size: 4vw;
    }
    
    .default-description-text{
        font-size: 3.5vh;
    } 
    .logo-header{
        border: 3px solid black;
    }
   
    
    .tiles{
        bottom: 10%;
    }
    .form-slide{
        flex-direction: column;
        gap: 1em;
        padding: 0 3em;
    }
    .form-button{
        width: 15vw;
    }
    .details{
        width: 90vw;
    }
    .creators-form-footer{
        gap:3vw;
    }
    .creators-form-footer-text{
        font-size: 3.5vw;
    }

    .form-slide-12 .calendar{
        width: 15em;
        margin-top: 0.5em;
        font-size: 1.2em;
    }
    .form-slide-13 .genders{
        width: 15em;
    }
    .form-slide{
        padding: 0 1em;
    }
    .form-slide-14 .form-button{
        width: 8em;
    }
    .slide-15-buttons{
        flex-direction: column;
    }
    .discord-button{
        margin-top: 0em;
    }
    .discord-button-text{
        font-size: 2.5vw;
    }
    
}
@media screen and (max-width:675px) {
    .default-header{
        padding: 5vh 0.5em;
    }
}

@media screen and (max-width:650px) {
    .lets-go-text{
        font-size: 3vw;
    }
    .close-form{
        top: 10%;
        right: 10%;
    }
    .close-icon{
        width: 2.5em;
    }
    .arrow-icon{
        width: 2.5em;
    }
    .member-count{
        padding: 0.5em 1.5em;
        margin-right: 0.5em;
    }
    
    .icon{
        width: 7vw;
    }
    
}

@media screen and (max-width:500px) {
    .default-header{
        flex-direction: column;
        padding:5vh 0;
        gap: 2em;
    }
    .logo-header-text{
        font-size: 2em;
        margin-left: 27vw;
    } 
 
    .default-logo{
        height: 23vw;
        top: -60%;
        left:2%;
    }
    
    
    .lets-go-text{
        font-size: 1em;
        white-space: nowrap;
    }
    .default-description{
        top: 30%;
    }
    .default-description-text{
        font-size: 2.5vh
    }
    .form-slide-11 .country-code,.form-slide-11 .creator-name{
        font-size: 6vw;
    }
    .count-container{
        flex-direction: column;
        bottom: -24vw;
        gap: 0.5em;
    }
    .creators-form-container{
        height: 630px;
    } 
    .dropdown-header{
        width: 100%;
    } 
    .dropdown-list{
        width: 100%;
    }
    .creator-everyday-work, .creator-pitch{
        font-size: 4vw;
    }
    .creator-submit{
        font-size: 1.5em;
        white-space: nowrap;
    }
    .discord-button{
        width: 80%;
    }
    .discord-button-text{
        font-size: 1.5em;
        
    }
}


@media screen and (max-width:460px){
    .close-form{
        top: 5%;
        right: 9%
    }
    
    .form-slide{
        padding: 0 1em;
    }
    .form-header{
        font-size: 7vw;
    }
    .form-footer{
        font-size: 3vw;
    }
    .form-button{
        width: 30vw;
    }
    .form-button-text{
        font-size: 6vw;
    }
    .creator-name{
        width: 80%;
        font-size: 7vw;
    }
    .form-footer-2{
        font-size: 6vw;
    }
    .form-slide-11 .creator-name{
        width: 40%;
    }
    .default-description-text{
        font-size: 4vw;
    }
}

@media screen and (max-width:350px) {
    .default-logo{
        height: 25vw;
        top: -70%;
        left:2%;
    }
    .logo-header-text{
        font-size: 1.5em;
    }
}

@media screen and  (min-height:550px){
    .creators-form-container{
        top:-11%;
    }
}
@media screen and  (min-height:650px){
    .creators-form-container{
        top:-10%;
    }
}
@media screen and  (min-height:780px){
    .creators-form-container{
        top:-8%;
    }
}
@media screen and  (min-height:900px){
    .default-header{
        padding: 4vh 0.3em;
    }
}
@media screen and  (min-height:910px){
    .creators-form-container{
        top:-7%;
    }
}
@media screen and  (min-height:1050px){
    .creators-form-container{
        top:0;
    }
}

@media screen and (min-height:1075px) {
    .default{
        height: 900px;
    }
    .default-header{
        flex-direction:column ;
        padding: 4em 1em;
    }
    .logo-header-text{
        font-size: min(8vw,5em);
    }
    .lets-go{
        margin-top: 4em;
    }
    .lets-go-text{
        font-size: min(6vw,3em);
    }
    .default-description-text{
        font-size: min(4vw,3em);
    }
    .default-description{
        top: 39%;
    }
    .default-logo{
        height: min(20vw, 12em);
    }
}

@media screen and (max-height:608px) {
    .default{
        height: 500px;
    }
}