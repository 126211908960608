.navbar.smaller{
    transition: all 0.3s ease;
    width: 60vw;
    border-radius: 50px;
    background: rgba(0, 0, 0,0.5);
    top: 1vw;
}
.navbar.hide{
    transform: translateY(-120%);
    transition: all 0.3s ease;
}

.navbar{
    height: 4em;
    width: 100vw;
    max-width: 90rem;
    margin: 0 auto;
    padding : 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top : 0;
    z-index:999 ;
    background: black;
    backdrop-filter: blur(10px); 
    transition: all 0.3s ease;
}


.mobMenu{
    display: none;
    object-fit: cover;
    height: 2.8rem;
    background-color: #ff5400;
    border-radius: 50%;
    cursor: pointer;
}
.logo{
    display: flex;
    align-items: center;
    justify-content: center;
}
.logo-img{
    object-fit: cover;
    width: 8em;
}
.logo-img:hover{
    transform: scale(1.05);
    transition: transform 0.3s ease;
}
.nav-items{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.nav-item{
    margin: 1em;
    font-size: 1.5em;
    font-weight: 700;
    color:white;
    transition: all 0.3s ease;
}
.nav-item:hover{
    color:#ff5400;
    padding-bottom: 0.2em;
    cursor: pointer;
}

.mobile-menu-container{
    display :none;
}

@media screen and (max-width:1030px) {
    .navbar.smaller{
        width: 40em;
    }
}
@media screen and (max-width:810px){
    .navbar{
        height: 10vh;
    }
    .nav-items{
        display: none;
    }
    .mobile-menu-container{
        display:flex
    }
    .mobMenu{
        display: block;
        object-fit: cover;
        height: 8vh;
        background-color: #ff5400;
        border-radius: 50%;
        cursor: pointer;
    }
    .mobile-menu-container{
        display: flex;
        align-items: flex-end;
    }
    .navMenu{
        position:absolute;
        top:0%;
        right: 0rem;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        padding: 0.5rem;
        height: fit-content;
        width:100%;
        max-width: 90rem;
        height: 100vh;
        background:black;
        list-style: none;        
    }
    .close-menu{
        background-color: #ff5400;
        position: absolute;
        top: 4%;
        right: 4%;
        width: 14vw;
        padding: 1em 1em;
        border-radius: 50%;
        cursor: pointer;
    }
    .listItem{
        font-size: 7vw;
        font-weight: 700;
        cursor: pointer;
        padding: 0.5rem 3rem;
        margin: 0.25rem;
        transition: all 0.3s ease;
    }
    .logo-img{
        width: auto;
        height: 8vh;
    }
    .navbar.smaller{
        width: 70vw;
    }
}

@media screen and (max-width:600px) {
    .navbar.smaller{
        width: 75vw;
    }
}
@media screen and (max-width:510px) {
    .navbar.smaller{
        width: 85vw;
    }
}
@media screen and (max-width:450px) {
    .navbar.smaller{
       opacity: 0;
    }
    .logo-img:hover{
        transform: scale(1);
    }
}