.home,.Mobile-About,.mobile-contact {
    height: 100vh;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
    overflow-x: hidden;
    transition: all 0.3s ease ;
  }
  
.reel {
    scroll-snap-align: start;
}

