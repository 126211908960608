.Bakery{
    height: 500px;
    width: 98vw;
    max-width: 90rem;
    margin: 0 auto;
    position: relative; 
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 50px;
    text-align: center;
    margin-top: 20px;
    transition: transform 0.3s ease;
    transform: scale(0.95);
}
.mobile-Bakery{
    display: none;
}
.card.active{
    transform: scale(1);
}


.bakery-header{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1em;
}
.page-2-heading{
    padding-top: 15px ;
    font-size: 100px;
    color: black;
    font-weight: 700;
}
.bakery-footer{
    color: black;
    font-size: 1.5em;
    font-weight: 700;
    margin-top: -0.5em;
}

.page-2-bullets, .green-bullets, .yellow-bullets, .blue-bullets, .pink-bullets{
    color: black;
    list-style: none;
    font-size: 2em;
    font-weight: 750;

}

.green-bullets,.yellow-bullets, .blue-bullets, .pink-bullets{
    display: flex;
    gap: 2.5rem;
}
.green-bullet{
    background-color: #1dff7d;
    border-radius: 50px;
    padding: 0.5rem 0.95rem;
    color: black;
    transition: all 0.3s ease;

}

.blue-bullet{
    background-color: #42b6ff;
    border-radius: 50px;
    padding: 0.5rem 0.95rem;
    color: black;
    transition: all 0.3s ease;
}

.yellow-bullet{
    background-color: #ffe25b;
    border-radius: 50px;
    padding: 0.5rem 0.95rem;
    color: black;
    transition: all 0.3s ease;
}

.pink-bullet{
    background-color: #ffb2da;
    border-radius: 50px;
    padding: 0.5rem 0.95rem;
    margin-bottom: 2rem;
    color: black;
    transition: all 0.3s ease;
}

.cookie{
    width: 10em;
    height: 10em;
    object-fit: cover;
    position: absolute;
    bottom: 6em;
    right: 2em;
    z-index: 900;
}

@media screen and (max-width:1000px) {
    .Bakery{
        height: 45vw;
    }
    .page-2-heading{
        font-size: 8vw;
    }
    .bakery-footer{
        font-size: 1.5vw;
    }
    .green-bullets, .blue-bullets,.yellow-bullets, .pink-bullets{
        gap: 1.5vw;
    }
    li{
        font-size: 2.8vw;
    }
    .cookie{
        width: 14vw;
        height: 14vw;
        bottom: -9vw;
    }
}

@media screen and (max-width: 680px) {

    .blue-bullet, .pink-bullet , .green-bullet, .yellow-bullet{
        padding: 0.2em 1em;
    }
}
@media screen and (max-width:450px) {
    .Bakery{
        display: none;
    }
    .mobile-Bakery{
        height: 80vh;
        width: 98vw;
        margin: 0 auto;
        position: relative; 
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: left;
        border-radius: 50px;
        text-align: center;
        margin-top: 20px;
    }
    .mobile-bakery-header{
        display: flex;
        flex-direction: column;
        align-items: left;
        text-align: left;
        margin-bottom: 1em;
    }
    .mobile-page-2-heading{
        padding-top: 50px ;
        font-size: 4em;
        color: black;
        font-weight: 700;
        margin-left: 7vw;   
        line-height: 0.9em;
    }
    .mobile-bakery-footer{
        color: black;
        font-size: 1.1em;
        font-weight: 700;
        margin-left: 8vw;
        margin-top: 1em;
    }
    .mobile-page2-bullets{
        display: flex;
        align-items: left;
        flex-direction: column;
        gap: 0.5em;
        padding-left: 2em;
        margin-top: -2em;
    }
    .mobile-cookie{
        width: 28vw;
        height: 28vw;
        object-fit: cover;
        margin-left: 65vw;
        margin-bottom: 2em;
        z-index: 900;
    }
    .mobile-green-bullets, .mobile-yellow-bullets, .mobile-blue-bullets, .mobile-pink-bullets{
        color: black;
        list-style: none;
        font-size: 2em;
        font-weight: 750;   
        flex-wrap: wrap;    
    }

    .mobile-green-bullets,.mobile-yellow-bullets, .mobile-blue-bullets, .mobile-pink-bullets{
        display: flex;
        align-items: left;
        gap: 0.25em;

    }
    .mobile-green-bullet{
        background-color: #1dff7d;
        border-radius: 50px;
        padding: 0.2rem 0.95rem;
        color: black;
        transition: all 0.3s ease;
        font-size: 0.45em;
    }

    .mobile-blue-bullet{
        background-color: #42b6ff;
        border-radius: 50px;
        padding: 0.2rem 0.95rem;
        color: black;
        transition: all 0.3s ease;
        font-size: 0.45em;
    }

    .mobile-yellow-bullet{
        background-color: #ffe25b;
        border-radius: 50px;
        padding: 0.2rem 0.95rem;
        color: black;
        transition: all 0.3s ease;
        font-size: 0.45em;

    }

    .mobile-pink-bullet{
        background-color: #ffb2da;
        border-radius: 50px;
        padding: 0.2rem 0.95rem;
        color: black;
        transition: all 0.3s ease;
        font-size: 0.45em;
    }

}

@media screen and (max-width:380px) {
    .mobile-Bakery{   
        height: 75vh;
        min-height: 530px;
        max-height: 600px;
    }
    .mobile-bakery-header{
        font-size: 0.8em;
    }
    .mobile-page2-bullets{
        margin-top: -4em;
        padding-top: 2em;
    }
    .mobile-cookie{
        width: 25vw;
        height: 25vw;
    }

}

@media screen and (max-width:328px) {

    .mobile-page2-bullets{
        margin-top: -2.5em;
        padding-top: 2em;
    }
    .mobile-pink-bullet, .mobile-blue-bullet, .mobile-green-bullet, .mobile-yellow-bullet{
        font-size: 0.3em;
    }
    .mobile-cookie{
        width: 25vw;
        height: 25vw;
    }

}