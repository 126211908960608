.bts-container{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    overflow: hidden;
    width: 98vw;
    max-width: 90rem;
    height: 640px;
    margin-bottom: -1em;
    border-radius: 50px;
}

.bts-carousel {
    overflow-x: hidden;
    width: 100%;
    height: 100%;
}
  
.bts-carousel-inner {
    width: 200%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
    transition: transform 0.5s ease-in-out;
  }
  
.bts-carousel-inner > * {
    display: inline-block;
}
.bts-img{
    object-fit: cover;
    width: 100%;
    height: 550px;
    border-radius: 50px;
}

.bts-img:hover{
    transform: scale(1);
}
@media screen and (max-width:1000px) {
    .bts-container{
        height: auto;
    }
    .bts-img{
        object-fit: contain;
        height: 100%;
    }
}
@media screen and (max-width:800px) {
    .bts-container{
        height: auto;
    }
   
    .bts-img{
        object-fit: contain;
        height: 100%;
        border-radius: 20px;
        margin:0 auto;
    }
}
@media screen and (max-width:450px) {
    .bts-container{
        height: auto;
    }
    .bts-carousel{
        width: 95%;
    }
   
    .bts-img{
        object-fit: contain;
        height: 100%;
        border-radius: 20px;
        margin:0 auto;
        width: 50%;
    }
}