.buttons{
    width: 95vw;
    max-width: 90rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1em;
    margin: 1.2rem auto;
    transition: transform 0.3s ease;
    transform: scale(0.95);
}

.card.active{
    transform: scale(1);
}

.buttons .left,.buttons .right{

    width: 50%;
    background-color: #e8e2d1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 1em;
    padding: 1em 1em;
    border-radius: 50px;
}   
.buttons-text p{
    font-size: 1em;
    font-weight: 700;
    color: black;
}
.buttons-button{
    background-color: #2653ae;
    width: 90%;
    border-radius: 50px;
    transition: all 0.3s ease;
    border: none;
    outline: none;
    cursor: pointer;
}
.buttons-button:hover{
    transform: scale(1.05);
}
.buttons-button p{
    color: white;
    padding: 0.5em 1em;
    font-size: 2.3em;
    font-weight: 700;
}

.icons{
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.8em;
}
.icons .icon{
    object-fit: cover;
    width: 4rem;
}

.icon:hover{
    transform: scale(1.1);
    transition: all 0.3s ease-in-out;
}


@media screen and (max-width:1180px) {

    .icons .icon{
        width: 5vw;
    }
}

@media screen and (max-width:1030px) {
    .buttons-text{
        font-size: 1.5vw;
    }
    .buttons-button .button-text{
        font-size: 2.5vw;
    }
}

@media screen and (max-width:450px) {
    .buttons{
        flex-wrap: wrap;
        gap: 1em;
        margin-top: 2em;
        transform: scale(1);
        overflow-x: hidden;
    }
    
    .card.active{
        transform: scale(1);
    }
    
    .buttons .left,.buttons .right{
    
        width: 100%;
        background-color: #e8e2d1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        gap: 1em;
        padding: 1em 1em;
        border-radius: 50px;
    }  
    .buttons-text{
        margin: 2.5em 1em;
    } 
    .buttons-text p{
        font-size: 2.5em;
        font-weight: 700;
        color: black;
    }
    .buttons-button{
        background-color: #2653ae;
        width: 80%;
        border-radius: 50px;
        transition: all 0.3s ease;
        border: none;
        outline: none;
        cursor: pointer;
    }

    .buttons-button .button-text {
        color: white;
        font-size: 5.5vw;
        font-weight: 700;
        
    }

    .icons .icon{
        object-fit: cover;
        width: 12vw;
    }
    
    img:hover{
        transform: scale(1.1);
        transition: all 0.3s ease-in-out;
    }
}