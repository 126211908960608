.form{
    height: 600px;
    width: 98vw;
    max-width: 90rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: rgb(20,20,20);
    padding: 0 2em;
    border-radius: 50px;
}

.form-text{
    font-size: 4rem;
    font-weight: 200;
    white-space: nowrap;
}
.form-position, .form-work{
    display: flex;
    gap:20px;
    align-items: center;
}
.dropdown{
    width: auto;
    height: 3rem;
    background-color: rgb(40,40,40);
    color: white;
    border-radius: 5px;
    margin: 1rem 2rem;
    padding: 3rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    position: relative;
    gap: 20px;

}
.dropdown-clicker{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius:1em;
    gap:20px;
}
.dropdown-text{
    font-size: 4em;
    color:grey
}
.dropdown-rotated-text{
    color: white;
    transition: all 0.3s ease;
}
.arrow{
    width: 2em;
    height: 1em;
    margin: 0 auto;
    transition: all 0.3s ease-out;
}

.arrow-rotated {

    transform: rotate(180deg);
    transition: all 0.3s ease-out;

  }
.dropdown-hidden{
    display: flex;
    flex-direction: column;
    background-color: rgba(50,50,50,0.5); 
    border: 1px solid white;
    border-radius: 5px;
    padding: 0 1rem;
    font-size: 1.2rem;
    position: absolute;
    top: 110%;
    left: 0rem;
    z-index: 99;
    backdrop-filter: blur(10px);
    animation: showUp 0.1s linear  1 ;
}

@keyframes showUp{
    0%{
        opacity: 0;
        transform: translateY(-1rem);
    }
    100%{
        opacity: 1;
        transform: translateY(0);
    }
}
.dropdown-hidden .dropdown-text{
    padding: 0.5em 1em;
    font-size: 1.5em;
    cursor: pointer;
    color: grey;   
    transition: all 0.3s ease; 
}
.dropdown-hidden .dropdown-text:hover{
    color: white;
}
.form-text-box{
    display: flex;
    position:relative;
}

.form-text-input{
    width: 25rem;
    height: 10rem;
    outline: none;
    background-color: rgb(40,40,40);
    color: white;
    border: 1px solid white;
    border-radius: 5px;
    margin: 1rem 3rem;
    padding: 1rem 1rem;
    font-size: 1.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    resize: none;
}
.arrow-2{
    width: 3.8em;
    height: 3.8em;
    border: 1px solid transparent;
    background-color: #ff5400;
    border-radius: 50%;
    padding: 0.5em 0.5em;
    margin: 0 auto;
    position: absolute;
    bottom:8%;
    left: 35rem;
    cursor: pointer;
    transition: all 0.3s ease;
}
.arrow-2-rotated{
    transform: rotate(90deg);
}

.second-form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    width: 98vw;
    max-width: 90rem;
    align-items: flex-start;
    margin: 1em auto;
    padding: 1em 2em;
    background-color: rgb(20,20,20);
    border-radius: 50px;
}



.contactForm{
    margin: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 10em;
}
.form-name ,.email {
    background: rgb(30,30,30);
    color: white;
    font-size: 2em;
    width: 50vw;
    max-width: 40rem;
    margin-top: 0.4rem;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.5rem;
}
.message-n-submit{
    display: flex;
    align-items: flex-start;
    justify-content: center;
}
.msg{
    background: rgb(30,30,30);
    color: white;
    font-size: 2em;
    margin-top: 0.4rem;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.5rem;
    height: 10rem;
    width: 60vw;
    max-width: 50rem;
    resize: none;
}
.submit{
    background: #ff5400;
    font-weight: 600;
    border: none;
    padding: 0.4rem 2.4rem;
    border-radius: 50px;
    margin: 2rem;
    cursor: pointer;
    transition: all 0.2s ease;
    margin-top:13% ;    
}
.submit-arrow{
    width: 1rem;
    height: 1.5rem;
}
.submit:hover{
    background: white;
}




@media screen and (max-width:1050px) {
    .form-text{
        font-size: 3em;
    }
    .dropdown-text{
        font-size: 3.1em;
    }
    .dropdown{
        padding: 2em 1em;
        margin: 0.5em 0;
    }
    .second-form{
        margin: 1em auto;
        padding: 0 0;
    }    
    .contactForm{
        margin: 1rem;
        padding-left: 8vw;
    }
    .form-name ,.email {
        font-size: 2em;
        width: 60%;
    }

    .submit{
        margin-top:15% ;    
    }
}
@media screen and (max-width:810px) {
    .form-text{
        font-size: 2.5em;
    }
    .dropdown-text{
        font-size: 2.6em;
        margin: 0 0;
        padding: 0 0;
    }
    .arrow-2{
        width: 6vw;
        height: 6vw;
        left: 100%;
    }
    
}

@media screen and (max-width:760px) {

    .dropdown-text{
        font-size: 2.3em;
        margin: 0 0;
        padding: 0 0;
    } 
}
@media screen and (max-width:650px) {
    .form-text{
        font-size: 6vw;
    }
    .dropdown-text{
        font-size: 6vw;
    }
    .form-text-box{
        width: 80%;
    }
    .form{
        height: 450px;
    }

    .second-form{
        align-items: center;
    }
    
    .contactForm{
        margin: 2em auto;
        align-items: center;
        padding: 0 0;
        width: 88vw;
    }
    .form-name ,.email {
        font-size: 7vw;
        width: 100%;
        color: #c5c5c5;
    }
    .message-n-submit{
        flex-direction: column;
        align-items: center;
        justify-content: center;    
        width: 100%;
    }
    .msg{
        font-size: 7vw;
        height: 10rem;
        width: 100%;
        max-width: 50rem;
        resize: none;
        color: #c5c5c5;
    }
    .submit{
        padding: 1.4vw 7.4vw;
        border-radius: 50px;
        margin: 0 0;
        margin-top: 1.5em;   
    }
    .submit-arrow{
        width: 1rem;
        height: 1.5rem;
    }
    .error{
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
    }
}

@media screen and (max-width:620px) {
    .form-text{
        font-size: 5.5vw;
    }
    .dropdown-text{
        font-size: 5.5vw;
    }
    .arrow{
        width: 1.5em;
        height: 0.75em;
    }
}


@media screen and (max-width:550px) {
    .form{
        height: 650px;
        border-radius: 20px;
        align-items: flex-start;
        justify-content: center;
        gap: 0.8em;
        padding: 1em 1em;
    }
    .form-position, .form-work{
        flex-direction: column;
        align-items: unset;
        gap: 0px;
    }
    .form-text{
        font-size: 9vw;
        font-weight: 500;
    }
    .dropdown{
        padding: 2em 1em;
    }
    .dropdown-text{
        font-size: 9vw;
        white-space: nowrap;
    }
    .dropdown-hidden{
        width: 100%;
        padding: 1em 0em;
    }
    .dropdown-hidden .dropdown-text{
        font-size: 8vw;
        white-space: wrap;
    }

    .form-text-box{
        width: 100% ;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border: 2px solid reds;
    }
    .mobile-arrow-2{
        width: 3em;
        height: 3em;
        background-color: #ff5400;
        margin-top: 1em;
        border-radius: 50%;
        padding: 0.75em 0.75em;
        cursor: pointer;
        transition: all 0.3s ease;
    }
  
    .mobile-form-text-input{
        width: 100%;
        height: 10em;
        outline: none;
        border: none;
        background-color: rgb(40,40,40);
        border-radius: 0.5em;
        padding: 1em 1em;
        font-size: 1.5em;
        resize: none;
    }
}


@media screen and (max-width:410px) {
    .arrow{
        width: 1em;
        height: 0.5em;
    }
    .form-text-box{
        width: 90vw;
    }
}