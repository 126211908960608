.page1{
    height: 520px;
    background-color: #ebe27f;
    width: 98vw;
    max-width: 90rem;
    margin: 0 auto;
    overflow: hidden;
    position: relative; 
    border-radius: 50px;
    border-top-left-radius: 0rem;
    border-top-right-radius: 0rem;
    display: flex;
    flex-direction: column;
    text-align: left;
    gap:20px;
}
.page1 p{
    font-size: 1.3em;
    font-weight: 400;
    color: black;
    padding: 0 80px;
}
.about-page1-p-1{
    display: flex;
    margin-left: 3em;
}
.about-page1-para-1{ 
    display: flex;
    align-items: center;
    margin-left: -1.5em;
    
}
.about-page1-para-1 p{ 
    font-size: 1.7em;
    font-weight: 600;
}
.ek-idea-name{
    font-size: 1.2em;
    font-weight: 600;
    color: black;
    white-space: nowrap;
}
.pink-logo{
    object-fit: cover;
    width: 15em;
}

.page1 .about-page1-para-2{
    margin-top: -1em;
    font-weight: 600;
}

@media screen and (max-width:880px) {
    .page1 p{
        font-size: 1.2em;
        padding: 0 40px;
    }
    .about-page1-p-1{
        margin-left: 1em;
    }
    .about-page1-para-1 p{
        font-size: 1.4em;
    }
    .pink-logo{
        width: 25vw;
    }
}

@media screen and (max-width:880px) {
    .page1{
        height: 70vh;
    }
    .page1 p{
        font-size: 1.1em;
        padding: 0 40px;
    }
    .about-page1-para-1 p{
        font-size: 1.2em;
    }
    .pink-logo{
        width: 30vw;
    }
}
@media screen and (max-width:580px) {
    .page1{
        height: 115vw;
        padding-top: 0.8em;
    }
    .page1 p{
        font-size: 3.5vw;
    }
    .about-page1-para-1 p{
        font-size: 4vw;
    }
    .pink-logo{
        width: 35vw;
    }
}
@media screen and (max-width:430px) {
    .page1{
        height: 75vh;
        padding-top: 1em;
    }
    .page1 p{
        font-size: 0.95em;
    }
    .about-page1-p-1{
        margin-bottom: 1em;
    }
    .about-page1-para-1 p{
        font-size: 4vw;
        padding: 0 20px;
    }
    .pink-logo{
        width: 38vw;
    }
}


@media screen and (max-width:380px) {
    .page1{
        height: 80vh;
    }
    .page1 p{
        font-size: 0.92em;
    }
    .about-page1-p-1{
        gap: 1em;
    }
    .about-page1-para-1 p{
        font-size: 0.98em;
        padding: 0 20px;
    }
    .pink-logo{
        width: 38vw;
    }
}

