.brand-card{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1em 2em;
    background: white;
    border-radius: 1em;
    margin-top: 1em;
    overflow-x: hidden;
}
.brand-card img{
    object-fit: cover;
    width: 5em;
    background-color: white;
}
@media screen and (max-width:900px) {

    .brand-card img{
        width: 7vw;
    }
}
@media screen and (max-width:450px) {
    .brand-card{
        border-radius: 10px;
    }
    .brand-card img{
        width: 10vw;
    }
}
@media screen and (max-width:335px) {
    .brand-card{
        padding: 0.8em 1.2em;
    }
    .brand-card img{
        width: 12vw;
    }
}