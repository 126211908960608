.brands-heading{
  text-align: center;
  font-size: 3rem;
  font-weight: 700;
  color: white;
  white-space: nowrap;
  margin-top: 0.5em;
  margin-bottom: -1em;
}
.card{transition: transform 0.3s ease;
transform: scale(0.95);
}

.card.active{
transform: scale(1);
}


.brands{
    height: 350px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5em;
    text-align: center;
    margin: 0 auto;
    width: 98vw;
    max-width: 90rem;
    position: relative;
    margin-top: 4em;
    background: black;
}

.brands-left-slider{
    position: absolute;
    top: 0;
    display: flex;
    justify-content: space-between;
    gap: 1em;
    animation: move 180s linear infinite;
    overflow: hidden;
}


.brands-right-slider{
    position: absolute;
    top: 8em;
    display: flex;
    justify-content: space-between;
    gap: 1em;
    animation: moveback 180s linear infinite;
    overflow: hidden;
}


@keyframes move {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(-50%, 0);
    }
}

@keyframes moveback {
    0% {
      transform: translate(-50%, 0);
    }
    100% {
      transform: translate(0, 0);
    }
}


@media screen and (max-width:900px) {

    .brands{
        height: 35vw;
    }
    .brands-right-slider{
        top: 15vw;
    }
    .brands-heading{
      font-size: 5vw;
    }
}

@media screen and (max-width:450px) {
  .card{
    transform: scale(1);
  }
    
  .brands{
    height: 25vh;
  }
  .brands-heading{
    margin-top: 1em;
    margin-bottom: 0.5em;
    font-size: 6vw;
    font-weight: 600;
  }
  .mobile-brands-container{
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  .mobile-brands-slider{
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
  }
  .mobile-brands-left-slider-container{
    display:flex;
    align-items: center;
    width: 98vw;
    overflow-x: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  .mobile-brands-left-slider{
    display: flex;
    gap: 0.5em;
    animation: move 180s linear infinite;
  }
  .mobile-brands-right-slider-container{
    display:flex;
    align-items: center;
    width: 98vw;
    overflow-x: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  .mobile-brands-right-slider{
    display: flex;
    gap: 0.5em;
    animation: moveback 180s linear infinite;
    margin-top: -0.5em;
  }
  .mobile-brands-left-slider-container::-webkit-scrollbar,.mobile-brands-right-slider-container::-webkit-scrollbar{
    display: none;
  }
  
}


@media screen and (max-width:335px){ 
    .brands-heading{
      font-size: 6vw;
      font-weight: 600;
    }
}