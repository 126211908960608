.contact-button{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    overflow: hidden;
    width: 98vw;
    max-width: 90rem;
}
.contact-btn{
    margin:10px auto ;
    padding: 20px 20px;
    width: 78vw;
    max-width: 90rem;
    border: none;
    outline: none;
    background-color: #1680f9;
    cursor: pointer;
    border-radius: 40px;
    transition: transform 0.3s ease;
}
.contact-btn:hover{
    transform: scale(1.05);
}
.contact-btn-header{
    font-size: 3em;
    font-weight: 600;
    color: black;
}

.contact-greet{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 1em 0.5em;
}
.greet-text{
    font-size: 2em;
    color: #c5c5c5;
}

@media screen and (max-width:1000px) {

    .contact-btn{
        width: 88vw;
    }
    .contact-btn-header{
        font-size: 3vw;
        
    }
    
    .greet-text{
        font-size: 4.5vw;
    }
}
@media screen and (max-width:551px) {
    .contact-btn{
        height: 7em;
    }
    .contact-btn-header{
        font-size: 1.8em; 
        font-weight: 700;
    }
    .greet-text{
        font-size: 4.5vw;
    }
}
@media screen and (max-width:400px) {
    .contact-btn{
        height: 9em;
    }
    .contact-btn-header{
        font-size: 1.8em; 
        font-weight: 700;
    }
}
@media screen and (max-width:300px) {
    .contact-btn{
        height: 9em;
    }
    .contact-btn-header{
        font-size: 1.6em; 
        font-weight: 700;
    }
}