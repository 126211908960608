.intro{
    height: 500px;
    width: 98vw;
    max-width: 90rem;
    margin: 0 auto;
    overflow: hidden;
    position: relative; 
    border-radius: 50px;
    border-top-left-radius: 0rem;
    border-top-right-radius: 0rem;
    background: linear-gradient(#1d3c85,#2d64c3,#508dea,#a4d8fe,white);
}
.mobile-intro{
    display: none;
}

.intro-bg{    
    position: absolute; 
    top: 17em;
    left: 56em;
    z-index: 99 ;
    object-fit: cover;
    width: 150px;
    height: 150px;
}
.intro-content{
    display: flex;
    justify-content: space-between;
}

.intro-content .left{
    position: absolute;
    bottom: 1em;
    left: 2em;
    color: black;
    font-size: 1.2em;
    font-weight: 600;
    transition: all 0.6s ease;
}
.intro-content .right
{   
    position: absolute;
    bottom: 1rem;
    right: 2rem;
    color: black;
    font-size: 1.2rem;
    font-weight: 600;
    transition: all 0.6s ease;
}
.intro-content .left:hover, .intro-content .right:hover{
    color: #ff5400;
}
.intro-heading{
    font-size: 18em;
    font-weight: 700;
    letter-spacing: 1px;
    padding-left: 20px; 
    white-space: nowrap;
}

.intro-footer{
    font-size: 2em;
    font-weight: 700;
    padding-left: 35px; 
    margin-top: -2em;
}

@media screen and (max-width:1000px) {
    .intro{
        height: 45vw;
    }
    .intro-heading{
        font-size: 25vw;
    }
    .intro-footer{
        font-size: 3vw;
    }
    .intro-bg{
        position: absolute;
        top: 23vw;
        left: unset;
        right: 2em;
        width:15vw;
        height: 15vw;
    }
    .intro-content .left, .intro-content .right{
        font-size: 2vw;
    }
}

@media screen and (max-width:450px) {
    .intro{
        display: none;
    }
    .mobile-intro{
        display:block;
        height: 80vh;
        width: 98vw;
        max-width: 90rem;
        margin: 0 auto;
        overflow: hidden;
        position: relative; 
        border-radius: 50px;
        background: linear-gradient(#122661,#1a3a84,#1d3c85,#2d64c3,#508dea,#a4d8fe,white);
    }
    
    .mobile-intro-bg{    
        position: absolute; 
        bottom: 6em;
        right: 3em;
        z-index: 99 ;
        object-fit: cover;
        width: 150px;
        height: 150px;
    }
   
    .mobile-intro-heading{
        font-size: 9em;
        font-weight: 700;
        padding-left: 20px; 
        padding-top: 40px;
        text-align: left;
        line-height: 0.9em;
        color: #e7ebed;
    }
    .mobile-intro-footer{
        font-size: 1em;
        font-weight: 700;
        padding-left: 30px; 
        color: #e7ebed;      
    }
    .mobile-intro-content{
        display: flex;
    }
    .mobile-intro-content .left{
        position: absolute;
        bottom: 1em;
        left: 2em;
        color: black;
        font-size: 1.2em;
        font-weight: 600;
        transition: all 0.6s ease;
    }
    .mobile-intro-content .left:hover{
        color: #ff5400;
    }
}

@media screen and (max-width:350px) {
    .mobile-intro-heading{
        font-size: 40vw;
    }
    .mobile-intro-footer{
        font-size: 6vw;
    }
    .mobile-intro-bg{
        bottom: 7em;
    }
}