.between-mail{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    
}
.mail{
    display: flex;
    justify-content: space-between;
    padding: 1em 1em; 
    width: 98vw;
    max-width: 90rem;
}
.mail-text{
    color: #cecece;
    font-size: 2.5em;
    transition: all 0.3s ease-out;
    cursor: pointer;
}
.mail-container{
    display: flex;
    gap:0.5em
}
.mail-text:hover{
    color: #fff;
    transform: scale(1.05);
}

.mail-text-header{
    color: #cecece;
    font-size: 2.5em;
}

@media screen and (max-width:700px) {
    .between-mail{
        position: relative;
    }
    .mail-text{
        font-size: 6vw;
        transition: all 0.3s ease-out;
        cursor: pointer;
        white-space: nowrap;
    }
}

@media screen and (max-width:551px) {
    .mail{
        align-items: center;
        justify-content: center;
    }
    .mail-text-header{
        display: none;
    }
    .mail-text{
        font-size: 8vw;
        color: #fff;
    }
}