.gif{
    height: 500px;
    width: 98vw;
    max-width: 90rem;
    margin: 0 auto;
    position: relative; 
    display: flex;
    align-items: center;
    border-radius: 50px;
    margin-top: 20px;
    transition: transform 0.3s ease;
    transform: scale(0.95);
}
.card.active{
    transform: scale(1);
}

.background{
    object-fit: cover;
    width:100%;
    height: 100%;
    border-radius: 50px;
}
@media screen and (max-width:900px) {
    .gif{
        height: 45vw;
    }
}

@media screen and (max-width:450px) {

    .gif{
        height: 80vh;
        transform: scale(1); 
    }
    .background:hover{
        transform:  scale(1);
    }
}