.pdf-button-container{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    overflow: hidden;
    width: 98vw;
    max-width: 90rem;
}
.pdf-button{
    margin:20px auto ;
    padding: 20px 20px;
    width: 98vw;
    max-width: 90rem;
    border: none;
    outline: none;
    background-color: #6800d7;
    border-radius: 40px;
    cursor: pointer;
}
.pdf-button-header{
    font-size: 4em;
    font-weight: 600;
    text-decoration: underline;
}
.pdf-button-description{
    font-size: 1.5em;
    font-weight: 600;
}

@media screen and (max-width:650px) {
    .pdf-button-header{
        font-size: 10vw;
    }
    .pdf-button-description{
        font-size: 4vw;
        font-weight: 500;
    }
}