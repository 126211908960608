.slide-1{
    width: 98vw;
    max-width: 95rem;
    height: 540px;
    background-image: url(../../../../Assets//AboutCarousel/carousel\ 1.png);
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin: 0 auto;
    padding: 40px 40px;
    border-radius: 50px;
}
.page2-title-1{
    background-color: #60d854;
    border-radius: 30px;
    border: 5px solid black;
    width: 50%;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    white-space: nowrap;
}

.page2-title-1 p{
    font-size: 1.5em;
    font-weight: 700;
    color: black;
    padding: 20px 20px;
}

